import React, { PureComponent } from 'react';
import { navigate, withPrefix } from 'gatsby';
import browserLang from 'browser-lang';
import languages from './languages';

class Redirect extends PureComponent {
  constructor(props) {
    super(props);

    const langKeys = languages.map((language) => language.value);
    const { pathname } = props.location;

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const detected =
        window.localStorage.getItem('language') ||
        browserLang({
          languages: langKeys,
          fallback: 'en',
        });

      const hash = window.location.hash ? window.location.hash : '';
      const newUrl = withPrefix(`/${detected}${pathname}${hash}`);
      window.localStorage.setItem('language', detected);
      navigate(newUrl);
    }
  }

  render() {
    return <div />;
  }
}

export default Redirect;
